let downloadGameDemoURL = 'https://astroventure-game-demos.s3.us-east-2.amazonaws.com/' + process.env.ENVIRONMENT + '/UniversityOfMars';

function GetDownloadAndRedirect(e: Event, platform: string) {
    e.preventDefault();  //don't reload page so that we can redirect to download
    redirect: window.location.replace(downloadGameDemoURL + platform + 'Demo.zip')

}

let downloadButtonWindows = document.getElementById("windows-demo-dl-button");
if (downloadButtonWindows !== null) {
    downloadButtonWindows.addEventListener("click", function (e: Event) {
        GetDownloadAndRedirect(e, "Windows");
    });
}

let downloadButtonMac = document.getElementById("mac-demo-dl-button");
if (downloadButtonMac !== null) {
    downloadButtonMac.addEventListener("click", function (e: Event) {
        GetDownloadAndRedirect(e, "Mac");
    });
}

let downloadButtonLinux = document.getElementById("linux-demo-dl-button");
if (downloadButtonLinux !== null) {
    downloadButtonLinux.addEventListener("click", function (e: Event) {
        GetDownloadAndRedirect(e, "Linux");
    });
}
